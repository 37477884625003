import React, { useState, useEffect } from "react";

import { connect } from "react-redux";

// // import Custom Components
import OrderSummary from "./OrderSummary";

import { Stepper } from "react-form-stepper";

import { LoadingOverlay, Loader } from "react-overlay-loader";
import "react-overlay-loader/styles.css";
import ErrorsContainer from "./ErrorsContainer";
import OrderInfo from "./OrderInfo";
import PaymentsForm from "./PaymentForm";
import OrderPlaced from "./OrderPlaced";
import {
  getOrder,
  getProgress,
  resetCart,
  restoreCart,
  setOrder,
  setProgress,
} from "../../reducers/cart";
import { getIsAuthenticated, getUser } from "../../reducers/user";
import PaymentStatus from "./paymentStatus";
import { navigate } from "gatsby";
const STEPPER_STYLES = {
  activeBgColor: "orange",
  completedBgColor: "orange",
  size: "4em",
  circleFontSize: "2em",
  labelFontSize: "1.2rem",
};

const STEPS = [
  {
    label: "Order Details",
    children: <i className="fa fa-map " aria-hidden="true"></i>,
  },
  {
    label: "Payment Method",
    children: <i className="fa fa-credit-card fa-10x" aria-hidden="true"></i>,
  },
  {
    label: "Success",
    children: <i className="fa fa-check fa-10x" aria-hidden="true"></i>,
  },
];

const CheckoutForm = (props) => {
  const [processing, setProcessing] = useState(false);
  const [type, setType] = useState("");
  const [errors, setErrors] = useState(null);

  const componentCleanup = () => {
    console.log("unmount", props.progress);
    if (props.progress === "payment") {
      props.setProgress("info");
    } else if (props.progress === "success") {
      props.resetCart();
    }
  };
  useEffect(() => {
    console.log("mount");
    const query = new URLSearchParams(props.pageLocation.search);
    console.log(query, props.pageLocation.search, query.get("verify"));
    if (query.get("verify")) {
      console.log("Verify");
      props.setProgress("verify");
      navigate(`${window.location.pathname}?step=success`);
    }
    if (query.get("id")) {
      let url = `${
        process.env.GATSBY_API_BASE_URL
      }/api/v1/gatsby/orders/checkout/${query.get("id")}`;

      const headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${process.env.GATSBY_API_ACCESS_TOKEN}`,
      };

      fetch(url, { method: "GET", headers })
        .then((res) => {
          if (!res.ok) throw res;
          return res.json();
        })
        .then((order) => {
          props.setProgress("info");

          const cart = order.cart.map((item) => {
            const product = props.products.filter(
              (p) => p._id === item.productId
            );
            if (product.length > 0) {
              return {
                ...product[0],
                stock: product[0].quantity,
                quantity: item.quantity,
                isCrate: item.isCrate,
              };
            } else return null;
          });
          if (cart.includes(null)) {
            handleProcessing(false, "Error: Invalid order");
          } else {
            props.setOrder(order);

            props.restoreCart(cart);
          }

          // onProcessing();
        })
        .catch((err) => err.json().then((body) => {}));
    }
    if (props.order) {
      handleOrderType(props.order.type);
    }

    if (typeof window !== `undefined`) {
      window.addEventListener("beforeunload", componentCleanup);
    }

    return () => {
      componentCleanup();
      window.removeEventListener("beforeunload", componentCleanup);
    };
  }, []);

  const handleProcessing = (processing = false, errors = null) => {
    setProcessing(processing)
    setErrors(errors)
  };

  const handleOrderType = (value) => setType(value);

  const getActiveStep = (progress) => {
    switch (progress) {
      case "info":
        return 0;
      case "payment":
        return 1;
      case "success":
        return 2;

      default:
        return 0;
    }
  };
  const clickToTop = () => {
    if (typeof window !== "undefined") {
      window.scroll({ top: 0, left: 0, behavior: "smooth" });
    }
  };

  const { metadata, contact, order, progress } = props;
  return (
    <LoadingOverlay>
      <Loader text="Processing ..." loading={processing} />

      <div style={{ marginBottom: 36 }}>
        <Stepper
          steps={STEPS}
          size={"4em"}
          styleConfig={STEPPER_STYLES}
          activeStep={getActiveStep(progress)}
        />
      </div>
      <div className="page-content">
        <div className="container">
          {errors && <ErrorsContainer errors={errors} />}
          {errors && clickToTop()}
        </div>
        <div className="checkout">
          <div className="container">
            <div className="checkout row">
              <div className="col-lg-6 col-sm-12 col-xs-12">
                {progress === "info" && (
                  <OrderInfo
                    metadata={metadata}
                    contact={contact}
                    type={type}
                    onOrderTypeChange={handleOrderType}
                    onProcessing={handleProcessing}
                  />
                )}
                {progress === "payment" && (
                  <PaymentsForm
                    metadata={metadata}
                    paypalClientId={metadata.paypalClientId}
                    onProcessing={handleProcessing}
                    progress={progress}
                  />
                )}

                {progress === "verify" && (
                  <PaymentStatus
                    metadata={metadata}
                    onProcessing={handleProcessing}
                    progress={progress}
                    setProgress={props.setProgress}
                    orderNumber={order.orderNumber}
                    resetCart={props.resetCart}
                  />
                )}

                {progress === "success" && (
                  <OrderPlaced
                    orderNumber={order.orderNumber}
                    // type={order.type || "default"}
                    shipping={order.shipping}
                    metadata={metadata}
                    type={type}
                    email={order.customer?.email}
                    resetCart={props.resetCart}
                  />
                )}
              </div>
              <div className="col-lg-6 col-sm-12 col-xs-12 mt-5 mt-mb-0">
                <OrderSummary
                  progress={progress}
                  metadata={metadata}
                  type={type}
                  shipping={order.shipping}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </LoadingOverlay>
  );
};

const mapStateToProps = (state) => ({
  order: getOrder(state),
  progress: getProgress(state),
  customer: getUser(state),
  isAuthenticated: getIsAuthenticated(state),
});

const mapDispatchToProps = (dispatch) => ({
  setProgress: (value) => dispatch(setProgress(value)),
  setOrder: (value) => dispatch(setOrder(value)),
  restoreCart: (value) => dispatch(restoreCart(value)),
  resetCart: () => dispatch(resetCart()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutForm);