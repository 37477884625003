import React, { useState, useRef, useEffect } from "react";
import { navigate } from "gatsby";
import { connect } from "react-redux";
import SimpleReactValidator from "simple-react-validator";
import Modal from "react-modal";
// TODO: uncomment to allow paypal
import {
  PayPalScriptProvider,
  PayPalButtons,
  usePayPalScriptReducer,
  FUNDING,
} from "@paypal/react-paypal-js";

import {
  getDiscount,
  getOrder,
  setDiscount,
  resetDiscount,
  setProgress,
} from "../../reducers/cart";
import StripeCheckout from "./StripeCheckout";

Modal.setAppElement("#___gatsby");

// const CARD_OPTIONS = {
//   style: {
//     base: {
//       color: "#212529",
//       fontFamily: "Lato, sans-serif",
//       fontSize: "15px",
//       fontSmoothing: "antialiased",
//     },
//   },
// };

const ORDERS_BASE_URL = `${process.env.GATSBY_API_BASE_URL}/api/v1/gatsby/orders`;
const REQUEST_HEADERS = {
  Accept: "application/json",
  "Content-Type": "application/json",
  Authorization: `Bearer ${process.env.GATSBY_API_ACCESS_TOKEN}`,
};
/* TODO: uncomment to allow paypal */
const PayPalButtonsLoader = ({ createOrder, onApprove }) => {
  const [{ isPending, isResolved, isRejected }] = usePayPalScriptReducer();

  return (
    <>
      {isPending && (
        <>
          <p style={{ padding: "10px 56px" }}>
            <i className="fa fa-circle-o-notch fa-spin fa-2x fa-fw"></i>
          </p>
          <span className="sr-only">Loading...</span>
        </>
      )}
      {isResolved && (
        <div style={{ maxWidth: "150px" }}>
          <PayPalButtons
            style={{ height: 48 }}
            fundingSource={FUNDING.PAYPAL}
            createOrder={createOrder}
            onApprove={onApprove}
          />
        </div>
      )}
      {isRejected && (
        <div className="text-danger">Failed to load PayPal Buttons.</div>
      )}
    </>
  );
};

const PaymentsForm = (props) => {
  /* TODO: put in props to allow paypal paypalClientId */
  const { discount, paypalClientId } = props;
  const [paymentMethod, setPaymentMethod] = useState("stripe");
  const [showDiscountForm, setShowDiscountForm] = useState(false);
  const [discountCode, setDiscountCode] = useState("");
  // const [nameOnCard, setNameOnCard] = useState("");
  const [cardError, setCardError] = useState(null);
  const [open, setOpen] = useState(false);
  const [modalContent, setModalContent] = useState(0);
  const [clientSecret, setClientSecret] = useState(null);
  const [, forceUpdate] = useState();
  // const validator = useRef(
  //   new SimpleReactValidator({ autoForceUpdate: { forceUpdate: forceUpdate } })
  // );

  const dcValidator = useRef(
    new SimpleReactValidator({ autoForceUpdate: { forceUpdate: forceUpdate } })
  );
  useEffect(() => {
    const { code } = props.discount;
    const createPayment = async () => {
      if (code) setDiscountCode(code);
      const clientSecret = await createPaymentIntent();
      setClientSecret(clientSecret);
    };
    createPayment();
  }, []);

  const onOpenModal = () => setOpen(true);

  const onCloseModal = () => setOpen(false);

  // const setStateFromInput = (e) => {
  //   let obj = {};
  //   obj[e.target.name] = e.target.value;
  //   setState(obj);
  // };

  const handleDiscountForm = (e) => {
    e.preventDefault();
    setShowDiscountForm(!showDiscountForm);
  };

  const handleDiscount = async (e) => {
    e.preventDefault();

    const { order, onProcessing, setDiscount } = props;
    onProcessing(true);

    let formValidated = dcValidator.current.allValid();
    if (!formValidated) {
      onProcessing(false, "Please fill in the required fields.");
      dcValidator.current.showMessages();
      return;
    }
    const { orderId } = order;
    if (!orderId) return;

    const url = `${ORDERS_BASE_URL}/${orderId}/discount`;
    const method = "post";
    const headers = REQUEST_HEADERS;
    const body = JSON.stringify({ code: discountCode });

    const response = await fetch(url, { method, headers, body });
    if (response.ok) {
      const discount = await response.json();
      setDiscount(discount);
      const clientSecret = await createPaymentIntent();
      setClientSecret(clientSecret);
      onProcessing();
    } else {
      const err = await response.json();
      onProcessing(
        false,
        err.errors.map((error) => error.msg)
      );
      return;
    }
  };

  const removeDiscount = async (e) => {
    e.preventDefault();

    const { order, onProcessing, resetDiscount } = props;
    onProcessing(true);

    const url = `${ORDERS_BASE_URL}/${order.orderId}/discount`;
    const method = "delete";
    const headers = REQUEST_HEADERS;

    const response = await fetch(url, { method, headers });
    if (response.ok) {
      resetDiscount();
      const clientSecret = await createPaymentIntent();
      setClientSecret(clientSecret);
      onProcessing();
    } else {
      const err = await response.json();
      onProcessing(
        false,
        err.errors.map((error) => error.msg)
      );
      return;
    }
  };

  const handleCardInput = ({ error }) => {
    if (error) {
      setCardError(error.message);
    } else {
      setCardError(null);
    }
  };

  const handlePaymentMethod = (e) => setPaymentMethod(e.target.value);
  const createPaymentIntent = async () => {
    const { order, onProcessing } = props;
    const { orderId, customer } = order;
    if (!orderId) return;
    // if (!stripe || !elements || !orderId) return;

    const url = `${ORDERS_BASE_URL}/${orderId}/stripe`;
    const headers = REQUEST_HEADERS;

    const response = await fetch(url, { headers });
    if (response.ok) {
      const { clientSecret } = await response.json();
      return clientSecret;
    } else {
      const err = await response.json();
      console.log(err.errors.map((error) => error.msg),'error from create peyment intent')
      if (err.status === 409) {
        onProcessing(
          false,
          err.errors.map((error) => error.msg)
        );
      }
      return;
    }
  };

  const createPaypalTx = async () => {
    const { order, onProcessing } = props;
    const { orderId } = order;
    const url = `${ORDERS_BASE_URL}/${orderId}/paypal/create`;
    const headers = REQUEST_HEADERS;

    const res = await fetch(url, { method: "post", headers });
    if (!res.ok) {
      const err = await res.json();
      onProcessing(
        false,
        err.errors.map((error) => error.msg)
      );
      return;
    }
    const data = await res.json();
    return data.txId;
  };

  const approvePaypalTx = async () => {
    const { order, onProcessing, setProgress } = props;
    const { orderId } = order;
    onProcessing(true);

    const url = `${ORDERS_BASE_URL}/${orderId}/paypal/capture`;
    const headers = REQUEST_HEADERS;

    const res = await fetch(url, { method: "post", headers });
    if (!res.ok) {
      const err = await res.json();
      onProcessing(
        false,
        err.errors.map((error) => error.msg)
      );
      return;
    } else {
      setProgress("success");
      navigate(`${window.location.pathname}?step=success`);
      onProcessing();
    }
  };

  return (
    <>
      <div className="checkout-title">
        <h3>Payment</h3>
      </div>
      <div className="row">
        <div className="col">
          {showDiscountForm ? (
            <div className="cart-discount" style={{ minHeight: "40px" }}>
              <form className="form-inline mb-4" onSubmit={handleDiscount}>
                <div className=" input-group mr-3 mb-0">
                  <input
                    type="text"
                    name="discountCode"
                    className="form-control"
                    placeholder="Promo/Discount code"
                    value={discountCode}
                    readOnly={discount.code}
                    onChange={(e) => setDiscountCode(e.target.value)}
                  />
                  <div style={{ display: "inline" }}>
                    <button
                      type="submit"
                      className="btn btn-outline-primary-2"
                      //   style={{ marginTop: 34 }}
                      disabled={discount.code}
                    >
                      <i className="icon-long-arrow-right"></i>
                    </button>
                  </div>
                </div>

                {dcValidator.current.message(
                  "discountCode",
                  discountCode,
                  "required|min:3|alpha_num_dash_space"
                )}
                {discount.code && (
                  <div>
                    <span className="text-success mr-5">
                      <i className="fa fa-check-circle" aria-hidden="true"></i>{" "}
                      Your coupon was successfully applied.
                    </span>
                    <a
                      href="/#"
                      onClick={removeDiscount}
                      className="text-danger text-right"
                    >
                      Remove
                    </a>
                  </div>
                )}
              </form>
            </div>
          ) : (
            <div className="mb-4">
              <a
                href="/"
                onClick={handleDiscountForm}
                className="text-muted"
                style={{ textDecoration: "underline" }}
              >
                Got a discount code? Click here to enter!
              </a>
            </div>
          )}
        </div>
      </div>

      <div className="accordion theme-accordion" id="accordionPayment">
        <div className="card">
          <div className="card-header bg-white" id={"stripe"}>
            <div className="custom-control custom-radio">
              <fieldset>
                <input
                  type="radio"
                  className="custom-control-input"
                  name="payment-group"
                  id="card-payment"
                  defaultChecked={true}
                  value="stripe"
                  onClick={handlePaymentMethod}
                />
                <label
                  htmlFor="card-payment"
                  className="custom-control-label font-weight-bold"
                >
                  Credit Card
                </label>
              </fieldset>
              <span className="float-right text-muted">
                <img
                  src="/assets/images/payments-summary.png"
                  alt="payement methods"
                  height="24"
                  width="200"
                />{" "}
                and more...
              </span>
            </div>
          </div>

          <div
            className={`collapse ${paymentMethod === "stripe" && "show"}`}
            aria-labelledby="stripe"
            data-parent="#accordionPayment"
          >
            {clientSecret && (
              <StripeCheckout
                paymentMethod={paymentMethod}
                clientSecret={clientSecret}
                order={props.order}
                metadata={props.metadata}
                onProcessing={props.onProcessing}
                setProgress={props.setProgress}
                progress={props.progress}
              />
            )}
            {/* <CardElement
                        options={CARD_OPTIONS}
                        onChange={handleCardInput}
                      /> */}
          </div>
          {cardError && (
            <div className="srv-validation-message text-danger pt-1 pl-3">
              {cardError}
            </div>
          )}
        </div>

        {/* uncomment to allow paypal */}
        <div className="card">
          <div className="card-header bg-white" id={"palpay"}>
            <div className="custom-control custom-radio">
              <fieldset>
                <input
                  type="radio"
                  className="custom-control-input"
                  name="payment-group"
                  id="palpay-payment"
                  defaultChecked={false}
                  value="palpay"
                  onClick={handlePaymentMethod}
                />
                <label
                  htmlFor="palpay-payment"
                  className="custom-control-label font-weight-bold"
                >
                  PayPal
                </label>
              </fieldset>
            </div>
          </div>

          <div
            className={`collapse ${paymentMethod === "palpay" && "show"}`}
            aria-labelledby="palpay"
            data-parent="#accordionPayment"
          >
            <div className="card-body" style={{ backgroundColor: "#fafafa" }}>
              {paypalClientId ? (
                <div className="text-center">
                  <p className="mb-1">
                    <i
                      className="fa fa-external-link fa-5x"
                      aria-hidden="true"
                    ></i>
                  </p>
                  <p className="mb-0 px-3" style={{ lineHeight: 1.5 }}>
                    After clicking “Pay with PayPal” button, you will be
                    redirected to PayPal to complete your purchase securely.
                  </p>
                </div>
              ) : (
                "Not supported yet but coming soon !"
              )}
            </div>
          </div>
        </div>
      </div>
      <div style={{ marginTop: "25px" }}>
        <p>
          <span>By placing your order, you agree to our </span>
          <span
            className="a-declarative"
            data-action="help-popup"
            data-help-popup="{}"
          >
            <span className="help-node-link-wrapper">
              <a
                onClick={(e) => {
                  e.preventDefault();
                  onOpenModal();
                  setModalContent(0);
                }}
                className="a-link-normal"
                target="AmazonHelp"
                href="/#"
                rel="noopener"
              >
                {" "}
                <span className="text-primary">privacy</span>
              </a>
            </span>
          </span>
          <span> and </span>
          <span
            className="a-declarative"
            data-action="help-popup"
            data-help-popup="{}"
          >
            <span className="help-node-link-wrapper">
              <a
                onClick={(e) => {
                  e.preventDefault();
                  onOpenModal();
                  setModalContent(1);
                }}
                className="a-link-normal"
                target="AmazonHelp"
                href="/#"
                rel="noopener"
              >
                {" "}
                <span className="text-primary">terms</span>
              </a>
            </span>
          </span>
          <span>.</span>
        </p>
      </div>
      <div style={{ marginTop: "25px" }}>
        <div className="float-left">
          <button
            onClick={() => props.setProgress("info")}
            className="btn-link"
          >
            <span className="btn-text">Return to Shipping</span>
            <span className="btn-hover-text">Return to Shipping</span>
          </button>
        </div>

        {/* TODO: uncomment to allow paypal */}
        <div className="float-right">
          {paymentMethod === "palpay" && paypalClientId && (
            <PayPalScriptProvider options={{ "client-id": paypalClientId }}>
              <PayPalButtonsLoader
                createOrder={createPaypalTx}
                onApprove={approvePaypalTx}
              />
            </PayPalScriptProvider>
          )}
        </div>
      </div>

      <Modal isOpen={open} onRequestClose={onCloseModal} center>
        <div className="" role="document">
          <div className="modal-body p-2">
            <div className="row">
              {modalContent === 0 ? (
                <div className="col-sm-12 col-12">
                  <h2>THE AGREEMENT:</h2>
                  <p>
                    The use of this website and services on this website and
                    mobile application provided by owls.com (hereinafter
                    referred to as "Website") are subject to the following Terms
                    &amp; Conditions, all parts and sub-parts of which are
                    specifically incorporated by reference here. This Agreement
                    shall govern the use of all pages on this website
                    (hereinafter collectively referred to as "Website") and any
                    services provided by or on this Website ("Services").
                  </p>
                  <h4>DEFINITIONS:</h4>
                  <p>
                    “Agreement” denotes to this Terms and Conditions and the
                    Privacy Policy and other documents provided to you by the
                    Website; “We”, “us” and “our” are references to OWLS.COM;
                    “User”, “You” and “your” denote the person who is accessing
                    the website for taking or availing any service from us. User
                    shall include the company, partnership, sole trader, person,
                    body corporate or association taking services of this
                    Website; ” Website” shall mean and include owls.com and any
                    successor Website of the Company or any of its affiliates;
                    Parties: Collectively, the parties to this Agreement (We and
                    You) will be referred to as Parties.
                  </p>
                  <h4>ASSENT &amp; ACCEPTANCE:</h4>
                  <p>
                    By using the Website, you warrant that you have read and
                    reviewed this Agreement and that you agree to be bound by
                    it.
                  </p>
                  <h4>AGE RESTRICTION</h4>
                  <p>
                    You must be at least 13 (Thirteen) years of age to use this
                    Website or any Services contained herein. By using this
                    Website, you represent and warrant that you are at least 13
                    years of age and may legally agree to this Agreement. We
                    assume no responsibility or liability for any
                    misrepresentation of your age.
                  </p>
                  <h4>ORDERS &amp; PAYMENTS</h4>
                  <p>
                    All prices are listed in U.S. Dollars and subject to change
                    without notice. In the event of a pricing error, we reserve
                    the right to refuse or cancel an order placed. Payment is
                    required at time of purchase. We accept all major U.S.
                    credit cards including Visa, MasterCard, American Express,
                    and Discover as well as options like Amazon Pay and PayPal.
                    Once you place your order, your credit card is charged for
                    the amount of your order including tax and shipping charges.
                    You represent and warrant that (i) the payment information
                    you supply to us is true, correct, and complete, (ii) you
                    are duly authorized to use such payment methods for the
                    purchase, (iii) charges incurred by you will be honored by
                    the company assisting you with such payment, such as your
                    credit card company, and (iv) you will pay charges incurred
                    by you at the posted prices, including all applicable taxes,
                    if any. The Site should only list items that are in-stock.
                    However, inventory discrepancies can arise. We reserve the
                    right to correct any errors, inaccuracies, or omissions on
                    our Site at any time without prior notice. If we are unable
                    to deliver your item, we will cancel the order and notify
                    you within 3 business days of the date you placed your
                    order.
                  </p>
                  <h4>RETURN POLICY</h4>
                  <p>
                    <strong>Faulty Items</strong>&nbsp;
                    <br />
                    You can return your order, or part of it, if damaged, faulty
                    or with an error within 14-days after collecting it from the
                    store. In order to return your item, take your proof of
                    purchase and products that need to be returned to us. The
                    store can then inspect the item, and issue a refund or
                    replacement (if possible). Your proof of purchase can either
                    be your delivery note, confirmation email or invoice.
                    <br />
                    <strong>Returning Unwanted Items</strong>&nbsp;
                    <br />
                    To return unwanted items to our store, they must be in their
                    new, original condition for re-sale at full value.We reserve
                    the right to refuse returns or to charge you our fees and
                    expenses if the product is not received in a new, unopened
                    condition.
                  </p>
                  <h4>GENERAL CONDITION</h4>
                  <ul>
                    <li>
                      &nbsp;
                      <ul>
                        <li>
                          Photographs are for illustration only. Actual products
                          may vary slightly.
                        </li>
                        <li>
                          We do not guarantee the accuracy, completeness,
                          validity, or timeliness of information listed by us.
                        </li>
                        <li>
                          We make material changes to these terms and conditions
                          from time to time, we may notify you either by
                          prominently posting a notice of such changes or via
                          email communication.
                        </li>
                        <li>
                          The website is licensed to you on a limited,
                          non-exclusive, non-transferable, non-sublicensable
                          basis, solely to be used in connection with the
                          Service for your private, personal, non-commercial
                          use, subject to all the terms and conditions of this
                          Agreement as they apply to the Service.
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <h4>INTELLECTUAL PROPERTY</h4>
                  <p>
                    You agree that the Website and all Services provided by us
                    are the property of OWLS.com, including all copyrights,
                    trademarks, trade secrets, patents, and other intellectual
                    property.
                  </p>
                  <h4>AGE RESTRICTION</h4>
                  <p>
                    You must be at least 13 (Thirteen) years of age to use this
                    Website or any Services contained herein. By using this
                    Website, you represent and warrant that you are at least 13
                    years of age and may legally agree to this Agreement. We
                    assume no responsibility or liability for any
                    misrepresentation of your age.
                  </p>
                  <h4>ACCEPTABLE USE</h4>
                  <p>
                    You agree not to use the Website or Services for any
                    unlawful purpose or any purpose prohibited under this
                    clause. You agree not to use the Website or Services in any
                    way that could damage the Website, Services, or general
                    business of OWLS.com.
                  </p>
                  <h4>ASSUMPTION OF RISK</h4>
                  <p>
                    The Website and Services are provided for communication
                    purposes only. You acknowledge and agree that any
                    information posted on Our Website is not intended to be
                    legal advice, medical advice, or financial advice, and no
                    fiduciary relationship has been created between you and us.
                    You further agree that your purchase of any of the products
                    on the Website is at your own risk. We do not assume
                    responsibility or liability for any advice or other
                    information given on the Website.
                  </p>
                  <h4>REVERSE ENGINEERING &amp; SECURITY</h4>
                  <p>
                    You agree not to undertake any of the following actions:
                    <br />
                    <strong>a)</strong> Reverse engineer, or attempt to reverse
                    engineer or disassemble any code or software from or on the
                    Website or Services;
                    <br />
                    <strong>b)</strong> Violate the security of the Website or
                    Services through any unauthorized access, circumvention of
                    encryption or other security tools, data mining, or
                    interference to any host, user, or network.
                  </p>
                  <h4>INDEMNIFICATION</h4>
                  <p>
                    You agree to defend and indemnify us and any of our
                    affiliates (if applicable) and hold us harmless against any
                    legal claims and demands, including reasonable attorney's
                    fees, which may arise from or relate to your use or misuse
                    of the Website or Services, your breach of this Agreement,
                    or your conduct or actions. You agree that we shall be able
                    to select its legal counsel and may participate in its
                    defense if we wish.
                  </p>
                  <h4>EXCLUSION OF LIABILITY</h4>
                  <p>
                    You understand and agree that we (A) do not guarantee the
                    accuracy, completeness, validity, or timeliness of
                    information listed by us or any third parties; and (B) shall
                    not be responsible for any materials posted by us or any
                    third party. You shall use your judgment, caution, and
                    common sense in evaluating any prospective methods or offers
                    and any information provided by us or any third party.
                    Further, we shall not be liable for direct, indirect
                    consequential, or any other form of loss or damage that may
                    be suffered by a user through the use of the OWLS.com
                    Website including loss of data or information or any kind of
                    financial or physical loss or damage.
                  </p>
                  <h4>SPAM POLICY</h4>
                  <p>
                    You are strictly prohibited from using the Website or any of
                    our Services for illegal spam activities, including
                    gathering email addresses and personal information from
                    others or sending any mass commercial emails.
                  </p>
                  <h4>MODIFICATION &amp; VARIATION</h4>
                  <p>
                    We may, from time to time and at any time without notice to
                    you, modify this Agreement. You agree that we have the right
                    to modify this Agreement or revise anything contained
                    herein. You further agree that all modifications to this
                    Agreement are in full force and effect immediately upon
                    posting on the Website and that modifications or variations
                    will replace any prior version of this Agreement unless
                    prior versions are specifically referred to or incorporated
                    into the latest modification or variation of this Agreement.
                  </p>
                  <h4>ENTIRE AGREEMENT</h4>
                  <p>
                    This Agreement constitutes the entire understanding between
                    the Parties concerning any use of this Website. This
                    Agreement supersedes and replaces all prior or
                    contemporaneous agreements or understandings, written or
                    oral, regarding the use of this Website.
                  </p>
                  <h4>SERVICE INTERRUPTIONS</h4>
                  <p>
                    We may need to interrupt your access to the Website to
                    perform maintenance or emergency services on a scheduled or
                    unscheduled basis. You agree that your access to the Website
                    may be affected by unanticipated or unscheduled downtime,
                    for any reason, but that we shall have no liability for any
                    damage or loss caused as a result of such downtime.
                  </p>
                  <h4>TERM, TERMINATION &amp; SUSPENSION</h4>
                  <p>
                    We may terminate this Agreement with you at any time for any
                    reason, with or without cause. We specifically reserve the
                    right to terminate this Agreement if you violate any of the
                    terms outlined herein, including, but not limited to,
                    violating the intellectual property rights of us or a third
                    party, failing to comply with applicable laws or other legal
                    obligations, and/or publishing or distributing illegal
                    material. If you have registered for an account with Us, you
                    may also terminate this Agreement at any time by contacting
                    us and requesting termination. At the termination of this
                    Agreement, any provisions that would be expected to survive
                    termination by their nature shall remain in full force and
                    effect.
                  </p>
                  <h4>NO WARRANTIES</h4>
                  <p>
                    You agree that your use of the Website and Services is at
                    your sole and exclusive risk and that any Services provided
                    by us are on an "As Is" basis. We hereby expressly disclaim
                    any express or implied warranties of any kind, including,
                    but not limited to the implied warranty of fitness for a
                    particular purpose and the implied warranty of
                    merchantability. We make no warranties that the Website or
                    Services will meet your needs or that the Website or
                    Services will be uninterrupted, error-free, or secure.
                  </p>
                  <h4>GENERAL PROVISIONS</h4>
                  <ul>
                    <li>
                      <strong>JURISDICTION, VENUE &amp; CHOICE OF LAW:</strong>
                      <br />
                      The terms herein will be governed by and construed by the
                      laws of USA without giving effect to any principles of
                      conflicts of law. The Courts of USA shall have exclusive
                      jurisdiction over any dispute arising from the use of the
                      Website.
                    </li>
                    <li>
                      <strong>ASSIGNMENT:</strong>
                      <br />
                      This Agreement, or the rights granted hereunder, may not
                      be assigned, sold, leased, or otherwise transferred in
                      whole or part by you. Should this Agreement, or the rights
                      granted hereunder, be assigned, sold, leased, or otherwise
                      transferred by us, the rights and liabilities of OWLS.com
                      will bind and inure to any assignees, administrators,
                      successors, and executors.
                    </li>
                    <li>
                      <strong>SEVERABILITY:</strong>
                      <br />
                      If any part or sub-part of this Agreement is held invalid
                      or unenforceable by a court of law or competent
                      arbitrator, the remaining parts and sub-parts will be
                      enforced to the maximum extent possible. In such a
                      condition, the remainder of this Agreement shall continue
                      in full force.
                    </li>
                    <li>
                      <strong>NO WAIVER:</strong>
                      <br />
                      If we fail to enforce any provision of this Agreement,
                      this shall not constitute a waiver of any future
                      enforcement of that provision or any other provision.
                      Waiver of any part or sub-part of this Agreement will not
                      constitute a waiver of any other part or sub-part.
                    </li>
                    <li>
                      <strong>HEADINGS FOR CONVENIENCE ONLY:</strong>
                      <br />
                      Headings of parts and sub-parts under this Agreement are
                      for convenience and organization, only. Headings shall not
                      affect the meaning of any provisions of this Agreement.
                    </li>
                    <li>
                      <strong>NO AGENCY, PARTNERSHIP, OR JOINT VENTURE:</strong>
                      <br />
                      No agency, partnership, or joint venture has been created
                      between the Parties as a result of this Agreement. No
                      Party has any authority to bind the other to third
                      parties.
                    </li>
                    <li>
                      <strong>FORCE MAJEURE:</strong>
                      <br />
                      We are not liable for any failure to perform due to causes
                      beyond its reasonable control including, but not limited
                      to, acts of God, acts of civil authorities, acts of
                      military authorities, riots, embargoes, acts of nature,
                      and natural disasters, and other acts which may be due to
                      unforeseen circumstances, i.e., COVID-19!
                    </li>
                    <li>
                      <strong>ELECTRONIC COMMUNICATIONS PERMITTED:</strong>
                      <br />
                      Electronic communications are permitted to both Parties
                      under this Agreement, including e-mail. For any questions
                      or concerns, please use the contact us form on the website
                      or email us contact@owls.com
                    </li>
                  </ul>
                </div>
              ) : (
                <div className="col-sm-12 col-12">
                  <p>
                    We respect your privacy and the security of your personal
                    information. This Privacy Policy outlines how JS Products
                    and its subsidiaries and affiliates ( collectively, "JSP"
                    and occasionally referred to "us" or "we") manage the
                    collection, use, and sharing of data across this website
                    ("Site"), from emails, text messages, and other electronic
                    messages between you and us, or through mobile or desktop
                    applications related to this Site. Our Privacy Policy was
                    last updated on August 26, 2021.
                  </p>
                  <h4>Data Collection and Use:</h4>
                  <p>
                    This Section describes the information we collect when you
                    use our Site and how we make use of that information,
                    including what information is shared with third parties.
                    Some of the information is Personally Identifiable
                    Information ("PII"). When we use the term "PII", we mean
                    information that identifies, relates to, describes, is
                    reasonably capable of being associated with, or could
                    reasonably be linked, directly or indirectly, with a
                    particular consumer or household. PII does not include:
                  </p>
                  <p>Publicly available information from government records.</p>
                  <p>Deidentified or aggregated consumer information.</p>
                  <p>
                    Information excluded from the CCPA’s (defined below) scope,
                    like:
                  </p>
                  <p>
                    health or medical information covered by the Health
                    Insurance Portability and Accountability Act of 1996 (HIPAA)
                    and the California Confidentiality of Medical Information
                    Act (CMIA) or clinical trial data; or
                  </p>
                  <p>
                    personal information covered by certain sector-specific
                    privacy laws, including the Fair Credit Reporting Act
                    (FRCA), the Gramm-Leach-Bliley Act (GLBA) or California
                    Financial Information Privacy Act (FIPA), and the Driver’s
                    Privacy Protection Act of 1994.
                  </p>
                  <h4>California Consumers Rights:</h4>
                  <p>
                    California residents have specific rights on how we handle
                    their PII. The information in this Section 2 (California
                    Consumers Rights) applies to all users of our Site but the
                    additional rights apply solely to visitors, users, and
                    others who reside in the State of California, and do not
                    apply to employment-related PII collected from
                    California-based employees, job applicants, contractors, or
                    similar individuals. Additionally, where indicated, this
                    Section 2 (California Consumers Rights) does not apply to
                    PII reflecting a written or verbal business-to-business
                    communication ("B2B personal information"). You can learn
                    more about California consumer rights and the California
                    Consumer Privacy Act (CCPA) on California's OAG website. Any
                    terms defined in the CCPA have the same meaning when used in
                    this Section 2 (California Consumers Rights).
                    <br />
                    Categories of User Data, Sources, Purposes and Disclosure:
                    <br />
                    The list below details categories of PII data collected and
                    disclosed for a business purpose, sources of PII, and the
                    business purposes PII data was used in the last 12 months.
                    More information about these can be found above under
                    Section 1. Data Collection and Use.
                  </p>
                  <ol>
                    <li>
                      <strong>
                        Categories of PII Collected and Disclosed for a Business
                        Purpose in the Last 12 Months
                      </strong>
                      <br />
                      <ul>
                        <li>
                          Identifiers (e.g. name, ship-to and bill-to addresses,
                          email address, phone number for processing orders and
                          support requests)
                        </li>
                        <li>
                          Personal information categories listed in the
                          California Customer Records statute (Cal. Civ. Code §
                          1798.80(e)) (e.g. name, address, telephone number for
                          processing orders and support requests)
                        </li>
                        <li>
                          Commercial information (e.g. products purchased and
                          purchase history for processing orders)
                        </li>
                        <li>
                          Internet and network activity (e.g. search history for
                          marketing proposes)
                        </li>
                        <li>
                          Geolocation information (e.g. device location and IP
                          address for analytical and marketing purposes)
                        </li>
                        <li>
                          Sensory Data (e.g., audio recordings for support phone
                          calls)
                        </li>
                        <li>
                          Payment information (collected by our third party
                          payment processors for processing orders)
                        </li>
                        <li>Direct from our Site (e.g. making purchases)</li>
                        <li>Site account usage (e.g. creating an account)</li>
                        <li>Passive collection (e.g. browsing the Site)</li>
                        <li>
                          Communicating with us (e.g. contacting our support
                          team)
                        </li>
                        <li>
                          Email sign ups (e.g. newsletter subscribe forms and
                          giveaways)
                        </li>
                        <li>Processing Site orders</li>
                        <li>Analyzing Site usage</li>
                        <li>Maintaining and improving the Site</li>
                        <li>Communicating and responding to support issues</li>
                        <li>Administering giveaways</li>
                        <li>
                          Sending marketing, advertising and promotional
                          messages
                        </li>
                        <li>Complying with legal requirements</li>
                        <li>Fraud detection and prevention</li>
                        <li>Protecting us, our users and the general public</li>
                        <li>
                          Complete the transaction for which we collected the
                          PII, provide a good or service that you requested,
                          take actions reasonably anticipated within the context
                          of our ongoing business relationship with you, fulfill
                          the terms of a written warranty or product recall
                          conducted in accordance with federal law, or otherwise
                          perform our contract with you.
                        </li>
                        <li>
                          Detect security incidents, protect against malicious,
                          deceptive, fraudulent, or illegal activity, or
                          prosecute those responsible for such activities.
                        </li>
                        <li>
                          Debug products to identify and repair errors that
                          impair existing intended functionality.
                        </li>
                        <li>
                          Exercise free speech, ensure the right of another
                          consumer to exercise their free speech rights, or
                          exercise another right provided for by law.
                        </li>
                        <li>
                          Comply with the California Electronic Communications
                          Privacy Act (Cal. Penal Code § 1546 et. seq.).
                        </li>
                        <li>
                          Engage in public or peer-reviewed scientific,
                          historical, or statistical research in the public
                          interest that adheres to all other applicable ethics
                          and privacy laws, when the information’s deletion may
                          likely render impossible or seriously impair the
                          research’s achievement, if you previously provided
                          informed consent.
                        </li>
                        <li>
                          Enable solely internal uses that are reasonably
                          aligned with consumer expectations based on your
                          relationship with us.
                        </li>
                        <li>Comply with a legal obligation.</li>
                        <li>
                          Make other internal and lawful uses of that
                          information that are compatible with the context in
                          which you provided it.
                        </li>
                      </ul>
                    </li>
                  </ol>
                  <h4>Nevada Consumers Rights</h4>
                  <p>
                    Nevada residents have the right to request their PII is not
                    sold, even if the information is currently not being sold.
                    We have not sold PII and currently have no plans to sell
                    PII. To make a request to opt-out of potential future
                    selling of data, please send an email to
                    support@rakdistribution.net with the subject line "Nevada
                    Consumer Data Request".
                  </p>
                  <h4>Sharing Information with Third Parties</h4>
                  <p>
                    We may use third parties and affiliates to help us operate
                    our business and our Site or administer activities and
                    marketing on our behalf, such as payment processing, email
                    marketing, product and order support, and online
                    advertising. We may share your information with these third
                    parties for those limited purposes and more details about
                    the third parties can be found in our Privacy Policy above
                    under Section 1. Data Collection and Use. Additionally,
                    third parties such as advertisers, ad networks and servers,
                    content providers, and application providers may use cookies
                    or other tracking technologies to collect information about
                    you when you use our Site. We do not control these third
                    parties’ tracking technologies or how they may be used. If
                    you have any questions about an advertisement or other
                    targeted content, you should contact the responsible
                    provider directly.
                    <br />
                    Your PII may be passed on to a third party to evaluate or
                    conduct a merger, divestiture, restructuring,
                    reorganization, dissolution, or other sale or transfer of
                    some or all of our assets, whether as a going concern or as
                    part of bankruptcy, liquidation, or similar proceeding. We
                    may also disclose specific information when we determine
                    that such disclosure is necessary to comply with applicable
                    law or to protect the interests or safety of JSP or other
                    visitors to our Site.
                    <br />
                    For legal reasons, we will share information if we believe
                    that it is necessary to respond to law enforcement or
                    government requests as required by applicable law, court
                    order, or governmental regulations; investigate potential
                    violations of and enforce our Terms of Use; detect, prevent,
                    respond to fraud and security issues; and to protect against
                    harm to our rights, property, or safety of our users or the
                    public as required or permitted by law.
                  </p>
                  <h4>Technologies Used</h4>
                  <p>
                    The Site uses cookies, flash cookies, and web beacons to
                    keep track of your purchases and other activity on our Site
                    in order to enhance your experience on our Site.
                    <br />
                    A "cookie" is a small text file transferred by a website to
                    your device. Accepting the cookies used on our Site does not
                    give us access to your PII, but we may use cookies to
                    identify your device. Cookies are typically classified as
                    either "session" cookies or "permanent" cookies.
                    <br />
                    "Session" cookies do not stay on your device after you leave
                    our website or close your browser. The aggregate information
                    collected allows us to analyze traffic patterns on our Site.
                    This enables us over time to provide a better experience on
                    our Site by improving content and making our Site easier to
                    use.
                    <br />
                    "Permanent" cookies are those that remain on your device
                    after you leave our Site. They are used to facilitate
                    shopping, personalization, and account registration. For
                    example, cookies keep track of items in your cart as you
                    continue to shop and allow you to enter your password only
                    once on web pages where a login is required. "Permanent"
                    cookies can be manually removed by the user.
                    <br />
                    Most browsers automatically accept cookies by default, but
                    you can usually refuse cookies or selectively accept certain
                    cookies by adjusting the preferences in your browser. If you
                    turn off cookies, there may be some features of our Site
                    that will not be available to you and some web pages may not
                    display properly. Read how to manage cookies in Google
                    Chrome and how to manage cookies in Firefox.
                    <br />
                    JSP makes no representations or warranties about the
                    accuracy of the information contained in the above websites,
                    which are provided for reference only. Each user accepts all
                    responsibility for accessing these third-party websites and
                    assumes all risks.
                    <br />A "web beacon" is a small electronic file on our Site
                    or in our emails that allow us to count users who have
                    visited our Site or opened an email as well as other related
                    website statistics (for example, recording the popularity of
                    certain website content and verifying system and server
                    integrity).
                  </p>
                  <h4>Marketing and Advertising Choices</h4>
                  <p>
                    <strong>Email Marketing:</strong>
                    <br />
                    By signing up for our marketing emails or making a purchase
                    on our Site, you opt-in for us to send you various marketing
                    emails. You can opt-out of these emails at any time using
                    the link at the bottom of the emails or by contacting us
                    using one of the methods provided at in the "Contact Us"
                    section at the bottom of the page.
                  </p>
                  <p>
                    <strong>Text Messages:</strong>
                    <br />
                    To the extent you are receiving SMS messaging from us, you
                    may opt out of receiving SMS messaging by replying to any
                    message we send you with the word "STOP" or contacting us
                    using one of the methods provided at in the "Contact Us"
                    section at the bottom of the page. To the extent you receive
                    push notifications from our Progressive Web App (PWA) Site,
                    you may opt-out of them for your device through your account
                    and device settings.
                  </p>
                  <p>
                    <strong>Interest-Based Advertising:</strong>
                    <br />
                    We use several third party services to participate in
                    Interest-Based Advertising (IBA). This allows us to tailor
                    our display-ads to your individual interests based on your
                    online shopping and browsing behavior. Our third party
                    advertising providers also provide us with additional
                    advertising related services like ad delivery, reporting,
                    attribution, analytics, and market research. You can opt-out
                    of our advertising by visiting the National Advertising
                    Institute (NAI) opt-out page, Criteo’s Privacy Policy, and
                    Google’s opt-out page.
                  </p>
                  <p>
                    <strong>Opt-Out Note:</strong>
                    <br />
                    Please allow up to ten (10) business days for us and our
                    service providers to process your request. If you make a
                    purchase through our Site, you may be opted back in to our
                    marketing communications until you opt out or unsubscribe
                    once again. We may continue to send you transactional,
                    support, or operational communications even if you opt-out.
                  </p>
                  <h4>Security of Information</h4>
                  <p>
                    We use procedural and technological security measures, which
                    are reasonably designed to help protect PII from
                    unauthorized access or disclosure, consistent with industry
                    standards. When you place an order or submit information to
                    us via forms, the data is encrypted using industry standard
                    Secure Socket Layer (SSL) security technology. We use
                    encryption, passwords, and physical security measures to
                    help protect your PII against unauthorized access and
                    disclosure. However, it is always possible that third
                    parties will unlawfully intercept or access transmissions or
                    defeat these measures. Therefore, we do not promise and
                    cannot guarantee (and thus you should not expect) that PII
                    will never be viewed or used by others. While we are
                    committed to protecting your information, we cannot ensure
                    or warrant the security of any information you transmit to
                    us.
                  </p>
                  <h4>Links to Other Sites</h4>
                  <p>
                    The Site may contain links to other websites. We are not
                    responsible for the privacy practices or the content of such
                    web sites or for the privacy policies and practices of third
                    parties.
                  </p>
                  <h4>Policy Toward Children</h4>
                  <p>
                    Our Site is not targeted or intended for children under 16
                    years of age. No one under age 16 may provide any PII to or
                    on our Site. We do not knowingly collect PII from or about
                    children under age 16. If we are notified that we have
                    collected PII from such a child, we will delete all
                    information as soon as possible.
                  </p>
                  <h4>Privacy Policy Changes</h4>
                  <p>
                    We reserve the right to make changes to this Privacy Policy.
                    When we update our privacy policy, notice will be provided
                    in a pop up banner when you visit the Website after any
                    change. Your continued use of our Site after any changes
                    have been made will constitute acceptance of such changes.
                  </p>
                  <h4>Questions?</h4>
                  <p>
                    If you have any questions or concerns about our Privacy
                    Policy, please contact us using one of the methods provided
                    at in the "Contact Us" section at the bottom of the page.
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => ({
  order: getOrder(state),
  discount: getDiscount(state),
});

const mapDispatchToProps = (dispatch) => ({
  setDiscount: (data) => dispatch(setDiscount(data)),
  resetDiscount: () => dispatch(resetDiscount()),
  setProgress: (value) => dispatch(setProgress(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentsForm);