import React from "react"

const Errors = ({ errors }) => (
  <div className="row">
    <div className="col">
      <div className="alert alert-danger w-100" role="alert">
        {typeof errors === "string" ? (
          errors
        ) : (
          <ul>
            {errors.map((err, i) => (
              <li key={i} style={{ display: "list-item" }}>
                {err.includes('stock')?   `${err}, Please remove this product from your cart to continue` : err}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  </div>
)
export default Errors;
