import React, { useRef, useState } from "react"

import { Elements, PaymentElement } from "@stripe/react-stripe-js"
import { useStripe, useElements } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"
import { navigate } from "gatsby"

import SimpleReactValidator from "simple-react-validator"

let stripePromise
const getStripe = stripeAccount => {
  if (!stripePromise) {
    stripePromise = loadStripe(process.env.GATSBY_STRIPE_PK, { stripeAccount })
  }
  return stripePromise
}
const StripePaymentForm = ({
  paymentMethod,
  setProgress,
  onProcessing,
  order,
}) => {
  const stripe = useStripe()
  const elements = useElements()
  const validator = useRef(new SimpleReactValidator())
  // const [nameOnCard, setNameOnCard] = useState("");
  const handlePayment = async e => {
    e.preventDefault()

    let formValidated = validator.current.allValid()
    if (!formValidated) {
      onProcessing(false, "Please fill in the required fields.")
      validator.current.showMessages()
      return
    }

    onProcessing(true)
    const { orderId } = order
    if (!stripe || !elements || !orderId) return

    // const url = `${ORDERS_BASE_URL}/${orderId}/stripe`;
    // const headers = REQUEST_HEADERS;

    // const response = await fetch(url, { headers });
    // if (response.ok) {
    // const { clientSecret } = await response.json();
    const payload = await stripe.confirmPayment({
      elements,
      redirect: "if_required",
      confirmParams: {
        return_url:
          typeof window !== `undefined`
            ? `${window.location.href}&verify=true`
            : "",
        payment_method_data: {
          billing_details: {
            address: {
              line1: order.customer.address,
              city: order.customer.city,
              state: order.customer.state,
              postal_code: order.customer.zip,
              country: "US",
            },
          },
        },
        shipping: {
          name: `${order.customer.firstName} ${order.customer.lastName}`,
          address: {
            line1: order.customer.address,
            city: order.customer.city,
            state: order.customer.state,
            postal_code: order.customer.zip,
            country: "US",
          },
        },
      },
      //   payment_method: {
      //     card: elements.getElement(CardElement),
      //     billing_details: {
      //       name: this.state.nameOnCard,
      //       email: customer.email,
      //     },
      //   },
    })

    if (payload.error) {
      onProcessing(false, `Payment failed: ${payload.error.message}`)
      return
    }
    console.log("navigate checkout")
    setProgress("success")
    if (typeof window !== `undefined`) {
      navigate(`${window.location.pathname}?step=success`)
    }
    onProcessing()
  }
  return (
    <form onSubmit={handlePayment}>
      <div className=" mb-3" style={{ backgroundColor: "#fafafa" }}>
        <div className="form-group col-sm-12 mb-0">
          <div
            className="px-3 py-4 rounded bg-white"
            style={{ backgroundColor: "#fff" }}
          >
            <PaymentElement
              options={{
                // layout: {
                //   type: "accordion",
                //   defaultCollapsed: false,
                //   radios: true,
                //   spacedAccordionItems: false,
                // },
                fields: {
                  billingDetails: {
                    address: {
                      country: "never",
                      // postalCode: 'never'
                    },
                  },
                },
              }}
            />
          </div>
        </div>
      </div>
      <div className="float-right m-3">
        {paymentMethod === "stripe" && (
          <button type="submit" className="btn btn-solid" disabled={!stripe}>
            Place your order
          </button>
        )}
      </div>
    </form>
  )
}
function StripeCheckout({
  clientSecret,
  metadata,
  paymentMethod,
  order,
  onProcessing,
  setProgress,
}) {
  const stripePromise = getStripe(metadata.stripeAccount)

  return (
    <Elements stripe={stripePromise} options={{ clientSecret }}>
      <StripePaymentForm
        paymentMethod={paymentMethod}
        order={order}
        onProcessing={onProcessing}
        setProgress={setProgress}
      />
    </Elements>
  )
}

export default StripeCheckout
