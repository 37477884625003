import React, { useState } from "react";
import { Link } from "gatsby";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";

import { LoadingOverlay, Loader } from "react-overlay-loader";
import "react-overlay-loader/styles.css";

import { getIsAuthenticated, getUser, login } from "../../reducers/user";

const LoginFormInline = (props) => {
  const [error, setError] = useState([]);
  const [processing, setProcessing] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  // const handleProcessing = (processing = false, errors = null) => {
  //   setProcessing(processing);
  //   setError(errors);
  // };

  const onSubmit = async (data) => {
    setProcessing(true);
    props
      .login({ email: data.email, password: data.password })
      .then((data) => {
        // console.log(data);
        // handleProcessing();
        setProcessing(false);
        reset({
          email: "",
          password: "",
        });
      })
      .catch((err) => {
        if (err.response.status === 400)
          if (err.response.data.errors) {
            setProcessing(false);
            setError(err.response.data.errors);
          } else if (err.response.data.error) {
            setProcessing(false);
            setError([{ msg: err.response.data.error }]);
          }
      });
  };

  const { isAuthenticated, guest } = props;

  return (
    <>
      <LoadingOverlay>
        <Loader text="Processing ..." loading={processing} />
        {error && error.length > 0 && (
          <div className="alert alert-danger">
            {error.map((error, index) => (
              <div className="text-white" key={index}>
                {error.msg}
              </div>
            ))}
          </div>
        )}
        {!isAuthenticated && !guest && (
          <form onSubmit={handleSubmit(onSubmit)}>
            <h2 className="checkout-title">Sign in to your account</h2>
            <div className="row">
              <div className="col-sm-6">
                <div className="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="checkout-create-acc"
                    value={props.guest}
                    checked={props.guest}
                    onChange={props.setCheckbox}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="checkout-create-acc"
                  >
                    Continue as guest?
                  </label>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-5">
                <label htmlFor="email">Email address *</label>
                <input
                  id="email"
                  className="form-control"
                  name="email"
                  // value={state.email}
                  {...register("email", {
                    required: { value: true, message: "Email required" },
                    pattern: {
                      value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g,
                      message: "Please provide a valid email",
                    },
                  })}
                />
                {errors.email && (
                  <p className="text-danger">{errors.email?.message}</p>
                )}
              </div>
              <div className="col-sm-5">
                <label htmlFor="password">Password *</label>
                <input
                  id="password"
                  type="password"
                  className="form-control"
                  name="password"
                  {...register("password", {
                    required: {
                      value: true,
                      message: "You must specify a password",
                    },
                    minLength: {
                      value: 8,
                      message: "Password must have at least 8 characters",
                    },
                  })}
                />
                {errors.password && (
                  <p className="text-danger">{errors.password?.message}</p>
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-sm-4 col-md-4 col-lg-5 col-xl-4 mb-1">
                <button
                  // onClick={submitForm}
                  type="submit"
                  name="submit"
                  className="btn btn-primary btn-sm"
                  style={{ color: "#333333" }}
                >
                  <span>Login</span>
                  <i className="icon-long-arrow-right"></i>
                </button>
              </div>
              <div className="col-sm-4 col-md-4 col-l5-6 col-xl-4">
                <Link
                  to={"/signup/"}
                  className="btn btn-primary btn-sm align-bottom align-text-bottom"
                  style={{ color: "#333333" }}
                >
                  Create Account
                </Link>
              </div>
            </div>
          </form>
        )}
      </LoadingOverlay>
    </>
  );
};

const mapStateToProps = (state) => ({
  AuthUser: getUser(state),
  isAuthenticated: getIsAuthenticated(state),
});

const mapDispatchToProps = (dispatch) => ({
  login: (data) => dispatch(login(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginFormInline);